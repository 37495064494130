import React, { Suspense } from 'react';

import {
  BrowserRouter,
  Route,
  Routes,
} from 'react-router-dom';

import MaintenanceBanner from '../banner/maintenance.banner.component';
import ForgotPasswordComponent
  from '../forgot-password/forgot_password.component';
import LoginComponent from '../login/login.component';
import ProfileComponent from '../profile/profile.component';
import ViewReportComponent from '../report/report.component';
import NotificationComponent from '../signup/notification.component';
import SignUpComponent from '../signup/signup.component';
import CommoditySelection from '../subscription/commodity.component';
import StripePrice from '../subscription/stripe-price.component';
import { authRoutes } from './auth-routes';
import {
  ROUTE_COMMODITY_SELECTION_PATH,
  ROUTE_FORGOT_PASSWORD,
  ROUTE_LOGIN_PATH,
  ROUTE_NOTIFICATION_PATH,
  ROUTE_PROFILE_PATH,
  ROUTE_SIGN_UP_PATH,
  ROUTE_SUBSCRIPTION_PATH,
  ROUTE_VIEW_REPORT,
} from './constants';
import LayoutRoutes from './layout-routes';
import PrivateRoute from './private-route';
import Wrapper from './wrapper';

const Routers = () => {
        const showMaintenanceBanner = false;
        return (
                <BrowserRouter basename='/'>
                {showMaintenanceBanner && <MaintenanceBanner />}
                        <Wrapper>
                                <Suspense>
                                        <Routes>
                                                <Route path={'/'} element={<PrivateRoute />}>
                                                        <Route path={`/*`} element={<LayoutRoutes />} />
                                                </Route>
                                                <Route exact path={ROUTE_LOGIN_PATH} element={<LoginComponent />}  />
                                                <Route exact path={ROUTE_SIGN_UP_PATH} element={<SignUpComponent />} />   
                                                <Route exact path={ROUTE_SUBSCRIPTION_PATH} element={<StripePrice />} />
                                                <Route exact path={ROUTE_COMMODITY_SELECTION_PATH} element={<CommoditySelection />} />
                                                <Route exact path={ROUTE_NOTIFICATION_PATH} element={<NotificationComponent />}/>
                                                <Route exact path={ROUTE_PROFILE_PATH} element={<ProfileComponent />}/>
                                                <Route exact path={ROUTE_FORGOT_PASSWORD} element={<ForgotPasswordComponent />}/>
                                                <Route exact path={ROUTE_VIEW_REPORT} element={<ViewReportComponent />}/>

                                                {authRoutes.map(({ path, Component }) => (
                                                        <Route path={path} element={Component} key={path} />
                                                ))}
                                        </Routes>
                                </Suspense>
                        </Wrapper>

                </BrowserRouter>
        );
};
export default Routers;