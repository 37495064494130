import {
  useEffect,
  useState,
} from 'react';

import mixpanel from 'mixpanel-browser';

const useCheckMixpanelBlock = () => {
  const isNotProduction = process.env.REACT_APP_ENV === 'production';
  const [blocked, setBlocked] = useState(false);

  useEffect(() => {
  try {
    mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, { debug: isNotProduction });
  } catch (error) {
    setBlocked(true);
  }
  }, []);

  return blocked;
};

export default useCheckMixpanelBlock;