import './navbar.component.scss';

import React from 'react';

import { Auth } from 'aws-amplify';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';

import {
  faFileCircleQuestion,
  faSignOutAlt,
  faUser,
  faUserCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { trackButtonClick } from '../../mixpanel/mixpanel';
import { CONST_DISPLAY_NAME } from '../constant';
import {
  ROUTE_HOME_PATH,
  ROUTE_LOGIN_PATH,
  ROUTE_PROFILE_PATH,
} from '../routes/constants';

const Navbar = ({ dropdownVisible, toggleDropdown, name }) => {
  const navigate = useNavigate();
  const fullName = (localStorage.getItem(CONST_DISPLAY_NAME) ?? sessionStorage.getItem(CONST_DISPLAY_NAME)?? name)?.split(' ')[0].substring(0, 15);
  const navigateToProfile = () => navigate(ROUTE_PROFILE_PATH);
  const handleSignOut = async () => {
    try {
      trackButtonClick("Logout Button");
      await Auth.signOut();
      sessionStorage.clear();
      localStorage.clear();
      navigate(ROUTE_LOGIN_PATH);
    } catch (error) {
      toast.error(error?.message ?? 'Something went wrong', {toastId: 'logout-failed'});
    }
  };

  const handleHelp = () => {
    trackButtonClick("Help Button");
    const pdfUrl = 'https://www.minersdata.com/guide/Miners-Data-Handbook.pdf';
    window.open(pdfUrl, '_blank');
  };

  const navigateToHome = () => {
    navigate(ROUTE_HOME_PATH); // Navigate to /home
  };

  const handleKeyPress = (event) => {
    if (event.key === 'F1') {
      navigateToHome();
    }
  };

  return (<div className="navbar-container">
  <div className="logo" 
    onClick={navigateToHome} 
    onKeyDown={handleKeyPress}
    tabIndex={0}
    type="button"
    aria-label="Go to home">Md</div>
  <div className="user-name-wrapper">
    <Dropdown isOpen={dropdownVisible} toggle={toggleDropdown}>
      {fullName && (
        <DropdownToggle caret className="user-name">
          <FontAwesomeIcon icon={faUserCircle} className="user-icon" />
          {fullName}
        </DropdownToggle>
      )}
      <DropdownMenu className="custom-dropdown-menu">
        <DropdownItem className="custom-dropdown-item" onClick={navigateToProfile}>
          <FontAwesomeIcon icon={faUser} className="dropdown-item-icon" /> My Profile
        </DropdownItem>
        <DropdownItem divider />
        <DropdownItem className="custom-dropdown-item" onClick={handleHelp}>
          <FontAwesomeIcon icon={faFileCircleQuestion} className="dropdown-item-icon" /> Help
        </DropdownItem>
        <DropdownItem divider />
        <DropdownItem className="custom-dropdown-item" onClick={handleSignOut}>
          <FontAwesomeIcon icon={faSignOutAlt} className="dropdown-item-icon" /> Logout
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  </div>
</div>);

};

Navbar.propTypes = {
    dropdownVisible: PropTypes.bool.isRequired,
    toggleDropdown: PropTypes.func.isRequired,
  };

export default Navbar;
