import './commodity.component.scss';

import React, {
  useEffect,
  useState,
} from 'react';

import Multiselect from 'multiselect-react-dropdown';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Button,
  Spinner,
} from 'reactstrap';

import { CONST_USER_ID } from '../../components/constant';
import {
  trackButtonClick,
  trackEvent,
  trackPageView,
} from '../../mixpanel/mixpanel';
import handleNetworkError from '../error/handleNetworkError';
import { ROUTE_HOME_PATH } from '../routes/constants';
import fetchWrapper from '../utility/fetchWrapper';

const CommoditySelection = () => {
    const [key, setKey] = useState(0);
    const forceUpdate = () => {
        setKey(prevKey => prevKey + 1);
    };
    const [commodityOptions, setCommodityOptions] = useState([]);
    const [selectedCommodity, setSelectedCommodity] = useState([]);
    const [maxLimit, setMaxLimit] = useState();
    const [ loading, setLoading] = useState(false);
    const [immutableCommodities, setImmutableCommodities] = useState([]); 
    const navigate = useNavigate();

    useEffect(() => {
        trackPageView("Commodity Selection Page");
        getCommodityList();
    }, []);

    const getCommodityList = async () => {
        setLoading(true);
        try {
            const res = await fetchWrapper(`${process.env.REACT_APP_GET_COMMODITY_API_URL}${sessionStorage.getItem(CONST_USER_ID)}`);
            const data = await res.json;
            const commoditiesData = data.commodityDetails;
            const selectedCommodityKeys = data.subscriptions.commodity_list;
    
            const multiSelectOptions = commoditiesData.map(item => ({
                commodity_name: item.title,
                key: item.commodity_id
            }));
    
            const preSelectedCommodities = multiSelectOptions.filter(option =>
                selectedCommodityKeys.includes(option.key)
            );
    
            setCommodityOptions(multiSelectOptions);
            setSelectedCommodity(preSelectedCommodities);
            setImmutableCommodities(preSelectedCommodities); // Initialize immutable commodities
            setMaxLimit(data.subscriptions.commodity_limit || 0);
        } catch (error) {
            handleNetworkError(error, navigate, toast);
        } finally {
            setLoading(false);
        }
    };

    const onRemove = (selectedList, removedItem) => {
        const isImmutable = immutableCommodities.some(immutable => immutable.key === removedItem.key);
        if (isImmutable) {
            toast.dismiss();
            toast.error(`${removedItem.commodity_name} cannot be removed.`);
            // Use a functional update to ensure we're working with the most current state
            setSelectedCommodity(prevSelected => {
                // Check if the removed item is already in the list to avoid duplicates
                if (prevSelected.find(item => item.key === removedItem.key)) {
                    return [...prevSelected];
                } else {
                    return [...prevSelected, removedItem];
                }
            });
            forceUpdate();
        } else {
            setSelectedCommodity(selectedList);
            trackEvent(`removed commodity: ${removedItem.commodity_name}`);
        }
    };
    

    const updateCommodityList = async () => {
        trackButtonClick("Save Commodity Button");
        setLoading(true)
        const commodity_list = selectedCommodity.map(item => item.key).join(',');
        const requestOptions = {
			method: 'PUT',
			body: {
				user_id: sessionStorage.getItem(CONST_USER_ID),
				commodity_list: commodity_list
			}
		};
        await fetchWrapper(`${process.env.REACT_APP_UPDATE_COMMODITY_API_URL}`, requestOptions)
            .then((res) => {
                const data = res.json;
                if(data.statusCode === 200) {
                    setLoading(false)
                    navigate(ROUTE_HOME_PATH);
                }
                setLoading(false)
            }, (error) => {
                setLoading(false)
                handleNetworkError(error, navigate, toast);  
            })
    }
    return (
        <div className='commodity-screen'>
            <div className="commodity-selection-container">
                <h3 className='title'>
                    Commodity Selection
                </h3>
                <p className='description'>As per the current plan you can choose upto {maxLimit} commodities </p>
                <div className='mt-2'>
                    <Multiselect
                        key={key}
                        className="multiselect-dropdown"
                        displayValue="commodity_name" placeholder="Select commodities"
                        options={commodityOptions}
                        onSelect={(event) => {
                            trackEvent(`selected commodity: ${event}`)
                            return setSelectedCommodity(event);
                        }}
                        onRemove={onRemove}
                        selectedValues={selectedCommodity}
                        selectionLimit={maxLimit}
                    />
                </div>
                <Button className='mt-3' type='button' disabled={loading || !selectedCommodity.length} onClick={() => {updateCommodityList()}}>
                    {!loading ? "SAVE" : ""}
                    {loading ? (
                        <Spinner />
                    ) : null}
                </Button>
            </div>
        </div>
    )
}

export default CommoditySelection;