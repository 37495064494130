import './report.component.scss';

import React, {
  useEffect,
  useState,
} from 'react';

import {
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { toast } from 'react-toastify';
import { Container } from 'reactstrap';

import { faArrowLeftLong } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { trackButtonClick } from '../../mixpanel/mixpanel';
import handleNetworkError from '../error/handleNetworkError';
import Navbar from '../layout/navbar.component';
import fetchWrapper from '../utility/fetchWrapper';
import PowerBIReport from './powerbi-report';

const ViewReportComponent = () => {
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [embedToken, setEmbedToken] = useState(null);
    const [embedUrl, setEmbedUrl] = useState(null);
   const navigate = useNavigate();
    const location = useLocation();
    const { reportId, } = location.state || {};
    const { groupId, } = location.state || {};
    useEffect(() => {
        setDropdownVisible(false);
        setEmbedToken(null);
        setEmbedUrl(null);
        updateEmbedToken();
      }, []);

    function toggleDropdown() {
        trackButtonClick("Dropdown Button");
        setDropdownVisible(prevState => !prevState);
    }

    function goBackOnePage() {
        trackButtonClick("Back Button");
        navigate(-1);
    }

    const updateEmbedToken = async () => {
        const url = `${process.env.REACT_APP_GET_MS_AUTHORIZATION_TOKEN}`;
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({
                "group_id": groupId,
                "report_id": reportId
              })
        };
        
        await fetchWrapper(url,requestOptions)
            .then((res) => {
                const data = res.json;
                setEmbedToken(data.powerbi_token);
                setEmbedUrl(data.embed_url);
            }, (error) => {
                console.error(error, 'error');
                handleNetworkError(error, navigate, toast);
            });
    }

    const onTokenExpired = () => {
        //toast.dismiss
        toast.error('Session Expired. Please login again',{ toastId: "token-expiry" });
        updateEmbedToken();
    };



    return (
        <Container fluid={true} className="report-container">
            <Navbar
                dropdownVisible={dropdownVisible}
                toggleDropdown={toggleDropdown}
            />
            <div className="menu-item" onClick={goBackOnePage}>
            <FontAwesomeIcon className='icon' icon={faArrowLeftLong} />
            <span>Back</span>
          </div>
            {/* {!loading ?
                <PowerBIReport className='powerbi-container'  
                    reportId={reportId} 
                    accessToken={embedToken} 
                    embedUrl={embedUrl} 
                    onTokenExpired={onTokenExpired}/>
                :
                <Spinner className='spinner' />
            } */}
            <PowerBIReport className='powerbi-container'  
                    reportId={reportId} 
                    accessToken={embedToken} 
                    embedUrl={embedUrl} 
                    onTokenExpired={onTokenExpired}/>
        </Container>
    );
};

export default ViewReportComponent;
