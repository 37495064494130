import './forgot_password.scss';

import React, {
  useEffect,
  useState,
} from 'react';

import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Spinner } from 'reactstrap';

import {
  trackButtonClick,
  trackEvent,
  trackPageView,
} from '../../mixpanel/mixpanel';
import {
  DELAY_TIMER,
  OTP_DELAY_TIMER,
} from '../constant';
import {
  ROUTE_LOGIN_PATH,
  ROUTE_SIGN_UP_PATH,
} from '../routes/constants';

const ForgotPasswordComponent = () => {
  localStorage.clear();
  sessionStorage.clear();

  const [step, setStep] = useState('requestOTP');
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  //const [passwordStrength, setPasswordStrength] = useState('NA');
  const [showPassword, setShowPassword] = useState(false);
  const [isResendButtonDisabled, setIsResendButtonDisabled] = useState(false);
  const [countdown, setCountdown] = useState(OTP_DELAY_TIMER);
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    let intervalId;
  
    if (isResendButtonDisabled) {
      intervalId = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    } else {
      clearInterval(intervalId); // Clear the interval if the button is not disabled
    }
  
    return () => clearInterval(intervalId); 
  }, [isResendButtonDisabled]);

  useEffect(() => {
    if (countdown <= 0) {
      clearInterval(countdown);
      setIsResendButtonDisabled(false);
      setCountdown(OTP_DELAY_TIMER); 
    }
    trackPageView("Forgot Password Page");
  }, [countdown]);

  const handleSubmitEmail = async (e) => {
    trackButtonClick("Send OTP Button");
    e.preventDefault();
    console.log('OTP Requested');
    try {
      setLoading(true) ;
      await Auth.forgotPassword(email);
      setIsResendButtonDisabled(true); 
      setCountdown(OTP_DELAY_TIMER); 
      setStep('resetPassword');
    } catch (error) {
      toast.error(error.message, { toastId:error.message });
      console.error(error);
    }
      setLoading(false);
  };

  const handleResendOtp = async (e) => {
    trackButtonClick("Resend OTP Button");
    console.log('OTP Resent Successfully');
    e.preventDefault();
    setIsResendButtonDisabled(true); 
    setCountdown(OTP_DELAY_TIMER); 
    handleSubmitEmail(e);
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handlePasswordChange = (e) => {
    const password = e.target.value;
    setNewPassword(password);
    //checkPasswordStrength(password);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    try {
      setLoading(true) ;
    if (newPassword !== confirmPassword) {
      //toast.dismiss();
      toast.error('Passwords do not match. Please try again.', { toastId: "password-mismatch" });
      setLoading(false);
      return; 
    }
      await Auth.forgotPasswordSubmit(email, otp, newPassword);
      setConfirmPassword('');
      setNewPassword('');
      setOtp('');
      setEmail('');
      console.log('Password Reset Successful'); 
      toast.success('Password has been reset successfully!', { toastId: "password-reset-success" });
      trackEvent("Password Reset Successful");
      setTimeout(() => {
        navigate(ROUTE_LOGIN_PATH);
      }, DELAY_TIMER);
    } catch (err) {
      console.error(err);
      toast.error(err.message, { toastId:err.message }); 
    }
    setLoading(false);
  };

  return (
    <div className="forgot-password-container">
      <div className="form-container">
        {step === 'requestOTP' && (
          <form onSubmit={handleSubmitEmail}>
            <h2>Forgot Password</h2>
            <p className="description">
              Forgot your password? No worries! Just enter your registered email address below,
              and we&apos;ll send you a One Time Password (OTP) to help you set a new password.
            </p>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
              required
              className="email-input"
            />
            <button type="submit" 
            className="send-otp-button" 
            disabled={isLoading}>
              {!isLoading ? "SEND OTP" : ""}
              {isLoading ? (
                  <Spinner />
              ) : null}
            </button>
          </form>
        )}
        {step === 'resetPassword' && (
          <form onSubmit={handleResetPassword}>
            <h2>Reset Password</h2>
            <p className="description">
              If the email <b>{email}</b> is registered with us, you will receive an OTP. Please enter that OTP below to proceed with setting your new password.
            </p>
            <input
              type="text"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              placeholder="Enter OTP"
              required
              className="otp-input"
              maxLength={6}
            />
            <div className="new-password-input-group">
              <input
                id="newPassword"
                type={showPassword ? 'text' : 'password'}
                value={newPassword}
                onChange={handlePasswordChange}
                placeholder="New Password"
                required
                className="new-password-input"
              />
              <button
                  type="button"
                  className="btn btn-secondary btn-show-hide"
                  onClick={handleTogglePasswordVisibility}
                  >
                  {showPassword ? 'Hide' : 'Show'}
              </button>
            </div>
            {/* <div className={`password-strength ${passwordStrength}`}>
              Password Strength: {passwordStrength}
            </div> */}
            <div className="new-password-input-group">
              <input
                  id="confirmPassword"
                  type={showPassword ? 'text' : 'password'}
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
                placeholder="Confirm Password"
                required
                className="new-password-input"
              />
               <button
                  type="button"
                  className="btn btn-secondary btn-show-hide"
                  onClick={handleTogglePasswordVisibility}
                  >
                  {showPassword ? 'Hide' : 'Show'}
              </button>
            </div>
            <div className="button-container">
            <button type="button" className="request-otp-button" onClick={handleResendOtp} disabled={isResendButtonDisabled || isLoading}>
              RESEND OTP
            </button>
            <button type="submit" className="reset-password-button" onClick={handleResetPassword} disabled={isLoading}>
              {!isLoading ? "RESET" : ""}
              {isLoading ? (
                  <Spinner />
              ) : null}
            </button>
             </div>

             <p className={"timer"}>
              {isResendButtonDisabled ? `You can request to resend OTP in ${countdown} seconds` : 'You can request to resend OTP now'}
            </p>
          </form>
        )}
        <div className="options-container">
          <a href={ROUTE_LOGIN_PATH} className="option-link">Login</a>
          <span className="option-divider"> | </span>
          <a href={ROUTE_SIGN_UP_PATH} className="option-link">Sign Up</a>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordComponent;
