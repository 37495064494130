import { trackError } from '../../mixpanel/mixpanel';
import { ROUTE_LOGIN_PATH } from '../routes/constants';

const handleNetworkError = (error, navigate, toast) => {
    //toast.dismiss();
    if (error?.json?.message && error?.json?.message === 'The incoming token has expired') {
        trackError("Token Expired");
        toast.error('Time limit reached. Please login again.', { toastId: "token-expired" });
    } else {
        trackError(error?.json?.message ?? 'Network Error')
        toast.error(error.json.message, { toastId: error.json.message  });
    }

    navigate(ROUTE_LOGIN_PATH);
};

export default handleNetworkError;