import './home.component.scss';

import React, {
  useEffect,
  useState,
} from 'react';

import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Col,
  Container,
  Row,
  Spinner,
} from 'reactstrap';

import {
  faArrowRightLong,
  faLock,
  faUserLock,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  trackButtonClick,
  trackEvent,
  trackPageView,
} from '../../mixpanel/mixpanel';
import {
  CONST_DISPLAY_NAME,
  CONST_USER_ID,
} from '../constant';
import handleNetworkError from '../error/handleNetworkError';
import Navbar from '../layout/navbar.component';
import {
  ROUTE_COMMODITY_SELECTION_PATH,
  ROUTE_SUBSCRIPTION_PATH,
  ROUTE_VIEW_REPORT,
} from '../routes/constants';
import fetchWrapper from '../utility/fetchWrapper';

const Home = () => {
  const navigate = useNavigate();
  const [dropdownVisible, setDropdownVisible] = useState(false);
  function toggleDropdown() {
    setDropdownVisible(prevState => !prevState);
  }
  
  const [userName, setUserName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [userCommodityList, setUserCommodityList] = useState([]);
  const [userCommodityLimit, setUserCommodityLimit] = useState(0);
  const [defaultCommodityList, setDefaultCommodityList] = useState([]);
  const [loading, setLoading] = useState(true);

  const demoCommodity = {
    "reportId": "32f510e4-e20a-40a9-a949-0edd9a4ead81",
    "isActive": true,
    "groupId": "01008dd2-95d0-4626-a6cf-f5fdfe38bdb3",
    "shortCode": "DM",
    "colorCode": "#FFFFFF",
    "commodity_id": "miners-data-demo",
    "description": "This is a demo dashboard to get a glimpse of the features available in the platform. Subscribe to the platform to get access to the full dashboard.",
    "title": "Sample"
  };
  
  useEffect(() => {
    getCommodityList();
    if (userName) {
      localStorage.setItem(CONST_DISPLAY_NAME, userName);
    }
  }, [userName]);

  const getCommodityList = async () => {
    trackPageView("Home Page");
    setLoading(true);
    await fetchWrapper(`${process.env.REACT_APP_GET_USER_COMMODITY_API_URL}${sessionStorage.getItem(CONST_USER_ID)}`)
      .then((res) => {
        setUserName(res?.json?.user_name);
        setUserEmail(res?.json?.user_email);
        setUserCommodityList(res?.json?.commodity_list);
        setUserCommodityLimit(Number(res?.json?.commodity_limit ?? 0));
        const commodityInfo = res?.json?.commodity_info.Items;
        const filteredCommodityInfo = commodityInfo.filter(
          item => !res.json.commodity_list.some(userItem => userItem.shortCode === item.shortCode) && item.shortCode !== demoCommodity.shortCode
        );
        const orderedCommodities = [
          demoCommodity,
          ...res.json.commodity_list,
          ...filteredCommodityInfo
        ];
        setDefaultCommodityList(orderedCommodities);
      }, (error) => {
        handleNetworkError(error, navigate, toast);
      }).finally(() => {
        setLoading(false);
      });
  }
  const navigateToReport = (reportId, groupId) => {  
    navigate(ROUTE_VIEW_REPORT, { state: { reportId: reportId, groupId: groupId } });
    window.location.reload();
  }
  return (
      <Container fluid={true} className="home-container ">
      
      {!loading && userName && defaultCommodityList && userCommodityList ?
      <>
      <Navbar
          dropdownVisible={dropdownVisible}
          toggleDropdown={toggleDropdown}
          name={userName}
        />
        <h1 className='mt-4 mb-3 header-text'>Welcome Back, {userName}</h1>
        <h3 className='sub-header-text'>Dashboards</h3>
        <Row className='m-0'>
          {
            defaultCommodityList?.map(item => {
              const isItemInUserList = userCommodityList.find(userItem => userItem.shortCode === item.shortCode);
              const isUpgradeNeeded = !isItemInUserList && userCommodityList.length === Number(userCommodityLimit);
              const canSubscribeMore =!isItemInUserList && userCommodityList.length < Number(userCommodityLimit);
              const isPaymentNeeded = !isItemInUserList && Number(userCommodityLimit) === 0;
              const isDemoReport = item.commodity_id === 'miners-data-demo';
              const showRightArrow = (isItemInUserList || isDemoReport);
              const commodityWidgetStyle = isDemoReport
                ? { borderColor: '#D82121', padding: '24px', marginBottom: '24px' }
                : { padding: '24px', marginBottom: '24px' };
                return (
                    <Col xl="3" className='commodity-widget p-3' key={item.shortCode} style={commodityWidgetStyle}>
                      <h1 className='commodity-title'>{item.title}</h1>
                      {isDemoReport?<span> <p>{item.description} </p></span>: <span className='commodity-color mb-5' style={{ backgroundColor: item.colorCode }}>{item.shortCode}</span>}
                      <button className='btn btn-notification' 
                        onClick={()=>{
                          if(isDemoReport){
                            trackButtonClick('Clicked Demo');
                            return navigateToReport(item.reportId, item.groupId);
                          } 
                          else if(isPaymentNeeded){
                            trackEvent('Subscribe Clicked');
                            return navigate(ROUTE_SUBSCRIPTION_PATH,{
                              state:{
                                customerEmail: userEmail,
                              }
                            });
                          }
                          else if(isUpgradeNeeded){
                            trackEvent('Requesting Upgrade');
                            const encodedEmail = encodeURIComponent(userEmail);
                            window.open(`${process.env.REACT_APP_STRIPE_BILLING_PORTAL_URL}${encodedEmail}`, '_blank');
                          }
                          else if(canSubscribeMore){
                            navigate(ROUTE_COMMODITY_SELECTION_PATH);
                          }else{
                            trackButtonClick(`Clicked ${item.title}`)
                            return navigateToReport(isItemInUserList.reportId, isItemInUserList.groupId);
                          }
                        }} >
                        {!isItemInUserList && !isDemoReport && (
                          <FontAwesomeIcon 
                            className='icon-lock' 
                            icon={isUpgradeNeeded?faUserLock: faLock}
                          />
                        )}
                        {isItemInUserList || isDemoReport? 'View Dashboard': canSubscribeMore?'\u00A0 CHOOSE': '\u00A0 SUBSCRIBE' }
                        {showRightArrow && (
                          <FontAwesomeIcon 
                            className='icon-arrow' 
                            icon={faArrowRightLong}
                          />
                        )}
                      </button>
                    </Col>
                );
            })
          }
        </Row>
      </> : <Spinner className='spinner' />
      }
      </Container>
  );
}

export default Home;