// profile.component.js

import './profile.component.scss';

import React, {
  useEffect,
  useState,
} from 'react';

import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Button,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Spinner,
} from 'reactstrap';

import {
  faArrowLeftLong,
  faCircleUser,
  faCircleXmark,
  faReceipt,
  faRotateBack,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  trackButtonClick,
  trackEvent,
  trackPageView,
} from '../../mixpanel/mixpanel';
import {
  CONST_USER_ID,
  DELAY_TIMER,
} from '../constant';
import handleNetworkError from '../error/handleNetworkError';
import Navbar from '../layout/navbar.component';
import { ROUTE_LOGIN_PATH } from '../routes/constants';
import fetchWrapper from '../utility/fetchWrapper';

const ProfileComponent = () => {
  const navigate = useNavigate();
  const [initialProfile, setInitialProfile] = useState({});
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [ loading, setLoading] = useState(false);
  const [profile, setProfile] = useState({
    name: '',
    email: '',
    phone: '',
    dob: '',
    twitter: '',
    discord: ''
  });

  const [activeSection, setActiveSection] = useState('profile');
  const [changePassword, setChangePassword] = useState({
    oldPassword: '',
    newPassword: '',
    confirmPassword: ''
  });
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setChangePassword({ ...changePassword, [name]: value });
  };

  const handleSubmitChangePassword = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      if(changePassword.newPassword !== changePassword.confirmPassword){
        toast.error('New password and confirm password do not match', { toastId: "password-mismatch" });
        return;
      }
      if(changePassword.newPassword === changePassword.oldPassword){
        toast.error('New password and old password cannot be same', { toastId: "password-same" });
        return;
      }
      const user = await Auth.currentAuthenticatedUser();
       await Auth.changePassword(user, changePassword.oldPassword,changePassword.newPassword);
      toast.success('Password changed successfully. You will be logged out for security reasons.', { toastId: "password-changed" });
      setTimeout(() => {
        navigate(ROUTE_LOGIN_PATH);
      }, DELAY_TIMER);
    } catch (err) {
      console.error(err);
      toast.error(err.message, { toastId:err.message });
    }
    setLoading(false);
  };

  const handleProfileClick = () => {
    trackButtonClick("Profile Button");
    setActiveSection('profile');
  };
  
  const handleChangePasswordClick = () => {
    trackButtonClick("Change Password Button");
    setActiveSection('changePassword');
  };

  const getPlaceholder = (key) => {
    if (key === 'dob') {
      return 'DD/MM/YYYY'; 
    }
    return `Enter your ${key}...`;
  };

  function toggleDropdown() {
    setDropdownVisible(prevState => !prevState);
  }

  function goBackOnePage() {
    trackButtonClick("Back Button");
    navigate(-1); 
  }

  function openBillingPortal(){
    trackButtonClick("Billing Portal Button");
    const encodedEmail = encodeURIComponent(profile.email);
    window.open(`${process.env.REACT_APP_STRIPE_BILLING_PORTAL_URL}${encodedEmail}`, '_blank');
  }

  function handleCancelSubscription(){
    trackButtonClick("Cancel Subscription Button");
    openBillingPortal();
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfile({ ...profile, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    updateUserProfileDetails();
    trackEvent("Profile Updated");
  };

  useEffect(() => {
      trackPageView("Profile Page");
      getUserProfileDetails();
  }, []);

  const getUserProfileDetails = async () => {
    await fetchWrapper(`${process.env.REACT_APP_GET_USER_PROFILES_DETAILS_API_URL}${sessionStorage.getItem(CONST_USER_ID)}`)
      .then((res) => {
        if (res && res.json) {
          const userProfile = {
            name: res?.json?.name ?? '', 
             email: res?.json?.email?? '', 
             phone: res?.json?.phone?? '', 
             dob: res?.json?.dob?? '', 
            twitter: res?.json?.twitter ?? '', 
            discord: res?.json?.discord ?? '', 
          };
          setProfile(userProfile);
          setInitialProfile(userProfile);
        }else{
          toast.error('Something went wrong. Please try again later.', { toastId: "something-went-wrong" });
        }
      }, (error) => {
        console.error(error);
        handleNetworkError(error, navigate, toast);
      })
  }

  const updateUserProfileDetails = async () => {
    setLoading(true)
    const profileRequestOptions = {
      method: 'PUT',
      body: {
        name: profile.name,
        phone: profile.phone,
        dob: profile.dob,
        twitter: profile.twitter,
        discord: profile.discord,

      }
    };
    await fetchWrapper(`${process.env.REACT_APP_UPDATE_USER_PROFILES_DETAILS_API_URL}${sessionStorage.getItem(CONST_USER_ID)}`, profileRequestOptions)
    .then((res) => {
      if (res && res.json) {
        const statusCode = res.status;
          if(statusCode === 200) {
           toast.success('Profile updated successfully.', { toastId: "profile-updated" });
          }else{
            toast.error('Something went wrong. Status code: '+statusCode, { toastId: "something-went-wrong" });
          }
      }else{
        toast.error('Something went wrong. Please try again later.', { toastId: "something-went-wrong" });
      }
    }, (error) => {
      console.error(error);
      handleNetworkError(error, navigate, toast);
    });
    setLoading(false)
  }


  return (
    <div className="profile-page">
    {/* Navbar stays at the top */}
    <Navbar
      dropdownVisible={dropdownVisible}
      toggleDropdown={toggleDropdown}
      name={profile.name}
    />
    { profile.email.length>0 ?
          <>
      <div className="main-container">
        <div className="sidebar">
          {/* Sidebar items */}
          <div className="menu-item" onClick={goBackOnePage}>
            <FontAwesomeIcon className='icon' icon={faArrowLeftLong} />
            <span>Back</span>
          </div>
          <div className="menu-item" onClick={handleProfileClick}>
            <FontAwesomeIcon icon={faCircleUser} className="icon" />
            <span>My Profile</span>
          </div>
          <div className="menu-spacer"></div>
          <div className="menu-item" onClick={handleChangePasswordClick}>
            <FontAwesomeIcon icon={faRotateBack} className="icon" />
            <span>Change Password</span>
          </div>
          <div className="menu-item" onClick={openBillingPortal}>
            <FontAwesomeIcon icon={faReceipt} className="icon" />
            <span>Billings</span>
          </div>
          <div className="menu-item" onClick={handleCancelSubscription}>
            <FontAwesomeIcon icon={faCircleXmark} className="icon" />
            <span>Cancel Subscription</span>
          </div>
          {/* <div className="menu-divider"></div>
            <div className="menu-item  danger-item">
            <FontAwesomeIcon icon={faTrashCan} className="icon" />
            <span>Delete Account</span>
            </div> */}
          </div>
          {activeSection === 'profile' ? (
          <Container fluid={true} className="profile-container">
          <Form onSubmit={handleSubmit}>
            <h2 className="profile-title">My Profile</h2>
            <p className="profile-description">
            Welcome to your profile! Here you can review and update your personal details to keep your account information current.
            </p>
              {Object.entries(profile).map(([key, value]) => (
                <FormGroup key={key}>
                  <Label for={key}>{key.charAt(0).toUpperCase() + key.slice(1)}</Label>
                  <Input
                    className={`input-container ${key === 'email' ? 'no-hover' : ''}`}
                    type={key === 'email' ? 'email' : key === 'dob' ? 'date' : 'text'}
                    name={key}
                    id={key}
                    value={value}
                    onChange={handleInputChange}
                    placeholder={getPlaceholder(key)}
                    readOnly={key === 'email'}
                  />
                </FormGroup>
              ))}
              <Button className='submit-btn'   disabled={JSON.stringify(profile) === JSON.stringify(initialProfile)}> 
              {!loading ? "SAVE PROFILE" : ""}
                  {loading ? (
                      <Spinner />
                  ) : null}</Button>
            </Form>
          </Container>
          ) : activeSection === 'changePassword' ? (
            <Container fluid={true} className="change-password-container">
            <Form onSubmit={handleSubmitChangePassword}>
            <h2 className="change-password-title">Change Password</h2>
            <p className="change-password-description">
              To ensure your accounts security, please create a strong new password. It should be a mix of upper and lower case letters, numbers, and symbols, and ideally, it should be different from passwords you use elsewhere.
            </p>
            <FormGroup>
              <Label for="oldPassword">Old Password</Label>
              <div className="password-input-group">
                <Input
                  type={showPassword ? 'text' : 'password'}
                  name="oldPassword"
                  id="oldPassword"
                  value={changePassword.oldPassword}
                  onChange={handlePasswordChange}
                  placeholder="Enter your old password"
                  required
                  className="password-input"
                />
                <button
                  type="button"
                  className="btn btn-secondary btn-show-hide"
                  onClick={handleTogglePasswordVisibility}
                  >
                  {showPassword ? 'Hide' : 'Show'}
                </button>
              </div>
            </FormGroup>
            <FormGroup>
              <div className="change-password-description">
                <p>Your new password must meet the following criteria:</p>
                <ul className="password-criteria-list">
                <li>Must be at least 15 characters long</li>
                <li>Must contain an uppercase and a lowercase letter (A, z)</li>
                <li>Must contain a number</li>
                <li>Must contain a special character (!, %, @, #, etc.)</li>
              </ul>
              </div>
              <Label for="newPassword">New Password</Label>
              <div className="password-input-group">
                <Input
                  type={showPassword ? 'text' : 'password'}
                  name="newPassword"
                  id="newPassword"
                  value={changePassword.newPassword}
                  onChange={handlePasswordChange}
                  placeholder="Enter your new password"
                  required
                  className="password-input"
                />
                <button
                  type="button"
                  className="btn btn-secondary btn-show-hide"
                  onClick={handleTogglePasswordVisibility}
                  >
                  {showPassword ? 'Hide' : 'Show'}
                </button>
              </div>
            </FormGroup>
            <FormGroup>
              <Label for="confirmPassword">Confirm New Password</Label>
              <div className="password-input-group">
                <Input
                  type={showPassword ? 'text' : 'password'}
                  name="confirmPassword"
                  id="confirmPassword"
                  value={changePassword.confirmPassword}
                  onChange={handlePasswordChange}
                  placeholder="Confirm your new password"
                  required
                  className="password-input"
                />
                <button
                  type="button"
                  className="btn btn-secondary btn-show-hide"
                  onClick={handleTogglePasswordVisibility}
                  >
                  {showPassword ? 'Hide' : 'Show'}
                </button>
              </div>
            </FormGroup>
            <Button type="submit" className="submit-btn">CHANGE PASSWORD</Button>
          </Form>
            </Container>
        ) : null}
        </div>
        </>:<Spinner className='spinner' />
      }
      </div>
  );
};

export default ProfileComponent;