import './signup.component.scss';

import React from 'react';

import { useNavigate } from 'react-router-dom';
import {
  Button,
  Col,
  Container,
  Row,
} from 'reactstrap';

import logo from '../../assets/icons/ic_md.jpg';
import userCreateLogo from '../../assets/images/user_created_image.png';
import { ROUTE_HOME_PATH } from '../routes/constants';

const SignUpComponent = () => {
    const navigate =  useNavigate();

    const openHomeScreen = () => {
        try {
          navigate(ROUTE_HOME_PATH)
        } catch (error) {
          console.error('Error navigating to Dashboard', error);
        }
      }

    return (
        <Container fluid={true} className="p-0">
            <Row className="m-0">
                <Col xl='6 p-0' sm='12'>
                    <div className="signup-background">
                        <div className='text-container'>
                            <span className='header-text'>Miners Data</span>
                            <div style={{ fontSize: '4rem' }}>is Here!</div>
                            <div className='mt-3 sub-text'>The most Comprehensive database of drilling results at your finger tips</div>
                            <Row className='data-info'>
                                <Col lg='6'>
                                    <div className='dot'></div>
                                    <span>100,000+</span>
                                    <div>Drill Results</div>
                                </Col>
                                <Col lg='6'>
                                    <div className='dot'></div>
                                    <span>80+</span>
                                    <div>Countries</div>
                                </Col>
                            </Row>
                            <Row className='data-info'>
                                <Col lg='6'>
                                    <div className='dot'></div>
                                    <span>500+</span>
                                    <div>Companies</div>
                                </Col>
                                <Col lg='6'>
                                    <div className='dot'></div>
                                    <span>11 yrs</span>
                                    <div>Of Data Records</div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Col>
                <Col xl='6' sm='12' className='signup-container'>
                    <div className='notification-form-container'>
                        <img className='notification-md-icon' src={logo} alt='' />
                        <img className='user-created-icon' src={userCreateLogo} alt=''  width='30%' />
                    </div>
                    <div className='notification-form-container'>
                    <h1 className='text-center mb-3'>Account Created</h1>
                    <p className='notification-description'>Your sign up for Miners Data was a success! Your new account is now active, and you are all set to explore everything Miners Data has to offer.</p>
                    </div>

                    <Button className='notification-submit-btn mb-3' type='submit' onClick={() => openHomeScreen()}>
                        GOTO HOME
                    </Button>
                </Col>
            </Row>
        </Container>
    );
};
export default SignUpComponent;
