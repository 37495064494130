import './login.component.scss';

import React, {
  useEffect,
  useState,
} from 'react';

import { Auth } from 'aws-amplify';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Row,
  Spinner,
} from 'reactstrap';

import logo from '../../assets/icons/ic_md.jpg';
import {
  trackButtonClick,
  trackEmail,
  trackPageView,
} from '../../mixpanel/mixpanel';
import { CONST_USER_ID } from '../constant';
import {
  ROUTE_FORGOT_PASSWORD,
  ROUTE_HOME_PATH,
  ROUTE_SIGN_UP_PATH,
} from '../routes/constants';
import fetchWrapper from '../utility/fetchWrapper';

const LoginComponent = () => {
    localStorage.clear();
    sessionStorage.clear();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { register, formState: { errors }, handleSubmit } = useForm()
    const onSubmit = async (formData) => {
        trackButtonClick("Login Button");
        setLoading(true)
        getCognitoUserDetails(formData);
    }

    useEffect(() => {
        trackPageView("Login Page");
      }, []);

    const getCognitoUserDetails = async (formData) => {
        try {
            await Auth.signIn(formData.email, formData.password).then(response => {
                sessionStorage.setItem('accessToken', response.signInUserSession.idToken.jwtToken);
                getUserDetails(formData);
            });
        } catch (error) {
            if (error.toString().indexOf('NotAuthorizedException') === 0) {
                toast.error('Incorrect username or password.', { toastId: "incorrect-credentials" });
                setLoading(false);
            } else {
                console.error(error);
                setLoading(false);
            }
        }
    }

    const getUserDetails = async (formData) => {
        const requestOptions = {
            method: 'POST',
            body: ({
                email: formData.email
            })
        };
        await fetchWrapper(process.env.REACT_APP_GET_USER_DETAILS_API_URL, requestOptions)
            .then((res) => {
                const response = res.json.body;
                if (!response.user_detail) {
                    toast.warning('This account is not registered with us. Please sign up.', { toastId: "not-registered" });
                    setLoading(false)
                }
                else{
                    sessionStorage.setItem(CONST_USER_ID, response.user_detail.user_id);
                    trackEmail(formData.email);
                    navigate(ROUTE_HOME_PATH);
                }
                // else if (response.user_detail &&  !response?.payment_status) {
                //     sessionStorage.setItem(CONST_USER_ID, response.user_detail.user_id);
                //     trackEmail(formData.email);
                //     navigate(ROUTE_SUBSCRIPTION_PATH, {
                //         state: {
                //             customerEmail: formData.email,
                //         }
                //     });
                // } else if (response?.plan_detail?.Item?.payment_id && response?.plan_detail?.Item?.commodity_list?.length === 0) {
                //     sessionStorage.setItem(CONST_USER_ID, response.user_detail.user_id);
                //     trackEmail(formData.email);
                //     navigate(ROUTE_COMMODITY_SELECTION_PATH);
                // } else if (response?.plan_detail?.Item?.payment_id &&  response?.payment_status) {
                //     sessionStorage.setItem(CONST_USER_ID, response.user_detail.user_id);
                //     trackEmail(formData.email);
                //     navigate(ROUTE_HOME_PATH);
                // } else{
                //     toast.error('Something went wrong. Please try again later.', { toastId: "something-went-wrong" });
                // }
            }, (error) => {
                toast.error(error.json, { toastId: error.json })
                setLoading(false)
            })
    }

    const [showPassword, setShowPassword] = useState(false);

    const handleTogglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    
    return (
        <Container fluid={true} className="p-0">
            <Row className="m-0">
                <Col xl='12' sm='12' className='d-flex align-items-center justify-content-center'>
                    <div className="parent-container">
                        <div className='form-container login-container'>
                            <img className='md-icon' src={logo} alt='' />
                            <h1 className='text-center font-size mb-3'>Welcome Back!</h1>
                            <h5 className='text-center font-size mb-3'>Login to your account below</h5>
                            <Form className='input-container' onSubmit={handleSubmit(onSubmit)}>
                                <div className='email-input-group'>
                                    <label htmlFor="email">Email Address</label>
                                    <input
                                        id="email"
                                        name="email"
                                        className="form-control"
                                        placeholder="Enter Email.."
                                        type="email"
                                        {...register('email', { required: true })}
                                    />
                                    <div style={{ height: '50px' }}>
                                        <span className='text-danger'>{errors.email && errors.email.type === 'required' && 'Email is required'}</span>
                                    </div>
                                </div>
                                <FormGroup>
                                    <label htmlFor="password">Password</label>
                                    <a href={ROUTE_FORGOT_PASSWORD} className='forgot-password account-text'>Forgot Password?</a>
                                    <div className="input-group">
                                        <input
                                            id="password"
                                            name="password"
                                            placeholder="Enter Password.."
                                            className="form-control"
                                            type={showPassword ? 'text' : 'password'}
                                            {...register('password', { required: true })}
                                        />
                                        <button
                                            type="button"
                                            className="btn btn-secondary btn-show-hide"
                                            onClick={handleTogglePasswordVisibility}>
                                            {showPassword ? 'Hide' : 'Show'}
                                        </button>
                                    </div>
                                    <div >
                                        <span className='text-danger'>
                                            {errors.password && errors.password.type === 'required' && 'Password is required'}
                                        </span>
                                    </div>
                                </FormGroup>
                                <Button className='submit-btn mb-3' type='submit' disabled={loading}>
                                    {!loading ? "LOGIN" : ""}
                                    {loading ? (
                                        <Spinner />
                                    ) : null}
                                </Button>
                            </Form>
                            <p className='text-center font-size account-text'>Don&apos;t have an account? <a href={ROUTE_SIGN_UP_PATH}><strong>Sign Up</strong></a></p>
                        </div>
                    </div>
                </Col>
                </Row>
        </Container>
    );
};
export default LoginComponent;