import './report.component.scss';

import React from 'react';

import { models } from 'powerbi-client';
import { PowerBIEmbed } from 'powerbi-client-react';

const PowerBIReport = ({ reportId, embedUrl, accessToken, onTokenExpired }) => {
    // Function to determine if the device is mobile
    const isMobileDevice = () => {
        return (window.innerWidth <= 768); // Example breakpoint, adjust as needed
    };

    // Generate embed configuration with conditional settings for mobile
    const getEmbedConfig = () => {
        return {
            type: 'report',
            id: reportId,
            embedUrl: embedUrl,
            accessToken: accessToken,
            tokenType: models.TokenType.Embed,
            settings: {
                panes: {
                    filters: {
                        visible: false
                    },
                    pageNavigation: {
                        visible: false
                    }
                },
                bars: {
                    statusBar: {
                        visible: false
                    }
                },
                commands: [
                    {
                        copy: {
                            displayOption: models.CommandDisplayOption.Disabled,
                        }
                    }
                ],
                layoutType: isMobileDevice() ? models.LayoutType.MobileLandscape : models.LayoutType.Custom,
            }
        };
    };
    console.log('reportId', reportId);
    return (
        <PowerBIEmbed
            embedConfig={getEmbedConfig()}
            eventHandlers={
                new Map([
                    ['loaded', function () { console.log('Report loaded'); }],
                    ['rendered', function () { console.log('Report rendered'); }],
                    ['error', function (event) {
                        if (event.detail.message === 'TokenExpired') {
                            if (onTokenExpired) {
                                onTokenExpired();
                                return;
                            }
                        }
                        console.error(event.detail.message,'Load error');
                    }]
                ])
            }
            cssClassName={"powerbi-container"}
            getEmbeddedComponent={(embeddedReport) => {
                console.log('Embedded report object: ', embeddedReport);
                window.report = embeddedReport;
            }}
        />
    );
};

export default PowerBIReport;
