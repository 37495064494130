// Development
let identityPoolId = 'ap-southeast-2:d2e549f3-b6b9-45a3-82a1-af81385e7a0c';
let userPoolId = 'ap-southeast-2_VvY8uMIAy';
let userPoolWebClientId = '47kr6b5954qvqnag16sa7f2rhs';

if (process.env.REACT_APP_ENV === 'staging') {
  identityPoolId = 'ap-southeast-2:d2e549f3-b6b9-45a3-82a1-af81385e7a0c';
  userPoolId = 'ap-southeast-2_VvY8uMIAy';
  userPoolWebClientId = '47kr6b5954qvqnag16sa7f2rhs';
}

if (process.env.REACT_APP_ENV === 'production') {
  identityPoolId = 'ap-southeast-2:95bf1887-05fe-4a2a-9fa1-16e79e3edc5d';
  userPoolId = 'ap-southeast-2_xjFw6T9k5';
  userPoolWebClientId = 'idnplft1l21s4hc8sp6s2pmib';
}

const awsExports = {
  aws_project_region: 'ap-southeast-2',
  aws_cognito_region: 'ap-southeast-2',
  aws_cognito_identity_pool_id:
    `${identityPoolId}`,
  region: 'ap-southeast-2',
  identityPoolRegion: 'ap-southeast-2',
  userPoolId: `${userPoolId}`,
  userPoolWebClientId: `${userPoolWebClientId}`,
  mandatorySignIn: false,
  Auth: {
    region: 'ap-southeast-2',
    identityPoolRegion: 'ap-southeast-2',
    userPoolId: `${userPoolId}`,
    userPoolWebClientId: `${userPoolWebClientId}`,
    mandatorySignIn: false,
  },
};
export default awsExports
