import React from 'react';

import {
  Navigate,
  Outlet,
} from 'react-router-dom';

import { CONST_USER_ID } from '../../components/constant';
import { ROUTE_LOGIN_PATH } from './constants';

const PrivateRoute = () => {
    return (
        sessionStorage.getItem(CONST_USER_ID) ? <Outlet /> : <Navigate exact to={ROUTE_LOGIN_PATH} />
    );
};
export default PrivateRoute;
