import './signup.component.scss';

import React, {
  useEffect,
  useState,
} from 'react';

import { Auth } from 'aws-amplify';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Row,
  Spinner,
} from 'reactstrap';

import { faCreditCard } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import discordIcon from '../../assets/icons/ic_discord.png';
import linkedIcon from '../../assets/icons/ic_link.png';
import logo from '../../assets/icons/ic_md.jpg';
import xIcon from '../../assets/icons/ic_x.png';
import youtubeIcon from '../../assets/icons/ic_youtube.png';
import {
  trackButtonClick,
  trackEmail,
  trackName,
  trackPageView,
} from '../../mixpanel/mixpanel';
import {
  CONST_USER_ID,
  DISCORD_LINK,
  LinkedInLink,
  PRIVACY_LINK,
  TANDC_LINK,
  TwitterLink,
  YoutubeLink,
} from '../constant/index';
import {
  ROUTE_HOME_PATH,
  ROUTE_LOGIN_PATH,
  ROUTE_NOTIFICATION_PATH,
} from '../routes/constants';
import fetchWrapper from '../utility/fetchWrapper';

const SignUpComponent = () => {

    localStorage.clear();
    sessionStorage.clear();
    const [loading, setLoading] = useState(false);
    const [hasAgreedToTerms, setHasAgreedToTerms] = useState(false);
    const [hasReadPrivacyPolicy, setHasReadPrivacyPolicy] = useState(false);
    const navigate = useNavigate();
    const { register, formState: { errors }, handleSubmit } = useForm()
    const onSubmit = async (formData) => {
        try {
            setLoading(true)
            trackButtonClick("Signup Button");
            await Auth.signUp({
                username: formData.email,
                password: formData.password,
                attributes: {
                    email: formData.email, // optional
                },
                autoSignIn: { 
                    enabled: true,
                }
            }).then((value) => {
                console.info(value);
                getCognitoUserDetails(formData, true);
            })
        } catch (error) {
            if (error.toString().indexOf('UsernameExistsException') === 0) {
                getCognitoUserDetails(formData, false);
            } else {
                console.error('error signing up:', error);
                setLoading(false)
            }
        }
    }

    const getCognitoUserDetails = async (formData, isNewUser) => {
        try {
            await Auth.signIn(formData.email, formData.password).then(response => {
                trackName(formData.fullName);
                trackEmail(formData.email);
                sessionStorage.setItem('accessToken', response.signInUserSession.idToken.jwtToken);
                if (isNewUser) {
                    signupDynamo(formData, response.username);
                } else {
                    getUserDetails(formData);
                }
            });
        } catch (error) {
            if (error.toString().indexOf('NotAuthorizedException') === 0) {
                toast.error('Unable to create this account. Please login or reset your password.', { toastId: "incorrect-credentials" });
                setLoading(false);
            } else {
                console.error(error);
                setLoading(false);
            }
        }
    }

    const getUserDetails = async (formData) => {
        const requestOptions = {
            method: 'POST',
            body: ({
                email: formData.email
            })
        };
        await fetchWrapper(process.env.REACT_APP_GET_USER_DETAILS_API_URL, requestOptions)
            .then((res) => {
                const response = res.json.body;
                if (!response.user_detail) {
                    getCognitoUserDetails(formData, true);
                } else if (response?.plan_detail?.Item?.payment_id && response?.payment_status) {
                    toast.error('An account with the given email already exists.', { toastId: "already-exists" });
                    setLoading(false);
                } else{
                    navigate(ROUTE_NOTIFICATION_PATH);
                }
                // else if (response.user_detail) {
                //     sessionStorage.setItem(CONST_USER_ID, response.user_detail.user_id);
                //     navigate(ROUTE_SUBSCRIPTION_PATH, {
                //         state: {
                //         customerEmail: formData.email,
                //     }});
                // }else if (!response?.plan_detail?.Item?.commodity_list) {
                //     sessionStorage.setItem(CONST_USER_ID, response.user_detail.user_id);
                //     navigate(ROUTE_COMMODITY_SELECTION_PATH);
                // } 
            }, (error) => {
                toast.error(error.json, { toastId: error.json })
                setLoading(false)
            })
    }

    const signupDynamo = async (data, userId) => {
        const requestOptions = {
            method: 'POST',
            body: ({
                email: data.email,
                name: data.fullName,
                user_id: userId,
                creation_date: new Date().toString(),
                coupon_id: data.couponCode ? data.couponCode : ''
            })
        };
        await fetchWrapper(process.env.REACT_APP_SING_UP_API_URL, requestOptions)
            .then((res) => {
                console.info(res.json)
                sessionStorage.setItem(CONST_USER_ID, userId);
                navigate(ROUTE_HOME_PATH);
                window.location.reload();
            }, (error) => {
                toast.error(error.json, { toastId: error.json })
                setLoading(false)
            })
    }

    const [showPassword, setShowPassword] = useState(false);

    const handleTogglePasswordVisibility = () => {
        setShowPassword(!showPassword);
      };

    useEffect(() => {
        trackPageView("Signup Page");
      }, []);

    return (
        <Container fluid={true} className="p-0">
            <Row className="m-0">
                <Col xl='6 p-0' sm='12'>
                    <div className="signup-background">
                        <div className='text-container'>
                            <span className='header-text'>Miners Data</span>
                            <div style={{ fontSize: '4rem' }}>is Here!</div>
                            <div className='mt-3 sub-text'>The most Comprehensive database of drilling results at your finger tips</div>
                            <Row className='data-info'>
                                <Col lg='6'>
                                    <div className='dot'></div>
                                    <span>100,000+</span>
                                    <div>Drill Results</div>
                                </Col>
                                <Col lg='6'>
                                    <div className='dot'></div>
                                    <span>80+</span>
                                    <div>Countries</div>
                                </Col>
                            </Row>
                            <Row className='data-info'>
                                <Col lg='6'>
                                    <div className='dot'></div>
                                    <span>500+</span>
                                    <div>Companies</div>
                                </Col>
                                <Col lg='6'>
                                    <div className='dot'></div>
                                    <span>11 yrs</span>
                                    <div>Of Data Records</div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Col>
                <Col xl='6' sm='12' className='signup-container'>
                    <div className='form-container'>
                        <img className='md-icon' src={logo} alt='' />
                        <h1 className='text-center mb-3'>Create a free account</h1>
                        <div className='text-center credit-card-info'>
                            <FontAwesomeIcon 
                                className='icon-credit-card' 
                                icon={faCreditCard} />
                            <p>No credit card required</p>
                        </div>
                        <br></br>
                        <Form className='input-container' onSubmit={handleSubmit(onSubmit)}>
                            <FormGroup>
                            <label htmlFor="name">Full Name</label>
                                <input
                                    className="form-control"
                                    name="fullName"
                                    placeholder="Enter your full name"
                                    type="text"
                                    {...register('fullName', { required: true })}
                                />
                                <span className='text-danger'>{errors.fullName && errors.fullName.type === 'required' && 'Full Name is required'}</span>
                            </FormGroup>
                            <FormGroup>
                            <label htmlFor="email">Email Address</label>
                                <input
                                    id="email"
                                    name="email"
                                    className="form-control"
                                    placeholder="Enter your email"
                                    type="email"
                                    {...register('email', { required: true })}
                                />
                                <span className='text-danger'>{errors.email && errors.email.type === 'required' && 'Email is required'}</span>
                            </FormGroup>
                            <FormGroup>
                            <label htmlFor="password">Password</label>
                                <div className="input-group">
                                    <input
                                    id="password"
                                    name="password"
                                    placeholder="Create a password"
                                    className="form-control"
                                    type={showPassword ? 'text' : 'password'}
                                    {...register('password', {
                                        required: true,
                                        pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[_@$!%*#^()?&-])[A-Za-z\d_@$!%*#^()?&-]{8,}$/,
                                    })}
                                    />
                                    <button
                                        type="button"
                                        className="btn btn-secondary btn-show-hide"
                                        onClick={handleTogglePasswordVisibility}
                                        >
                                        {showPassword ? 'Hide' : 'Show'}
                                    </button>
                                </div>
                            <div >
                                <span className="text-danger">
                                    {errors.password && errors.password.type === 'required' && 'Password is required'}
                                </span>
                                <span className="text-danger">
                                    {errors.password &&
                                        errors.password.type === 'pattern' &&
                                        'Password should contain 8 characters, One Special character, One Uppercase, One Number'}
                                </span>
                            </div>
                            </FormGroup>
                            <FormGroup check className="conditions-text">
                                <Input 
                                    id="privacyCheckbox"
                                    type="checkbox" 
                                    className='checkbox'
                                    checked={hasReadPrivacyPolicy} 
                                    onChange={(e) => setHasReadPrivacyPolicy(e.target.checked)} 
                                />{' '}
                                I have read the <a href={PRIVACY_LINK} target="_blank" rel="noopener noreferrer">Privacy Policy</a>
                            </FormGroup>
                            <FormGroup check className="conditions-text">
                                <Input 
                                    id="termsCheckbox"
                                    type="checkbox" 
                                    className='checkbox'
                                    checked={hasAgreedToTerms} 
                                    onChange={(e) => setHasAgreedToTerms(e.target.checked)} 
                                />{' '}
                                I agree to the <a href={TANDC_LINK} target="_blank" rel="noopener noreferrer">Terms and Conditions</a>
                            </FormGroup>
                            <Button className='submit-btn mb-3' type='submit' disabled={loading || !hasAgreedToTerms || !hasReadPrivacyPolicy}>
                                {!loading ? "SIGN UP" : ""}
                                {loading ? (
                                    <Spinner />
                                ) : null}
                            </Button>
                        </Form>
                        <p className='text-center font-size account-text'>Already have an account? <a href={ROUTE_LOGIN_PATH} className='login-link'><strong>Log In</strong></a></p>
                    </div>
                    <p className='social-link text-center mt-4'><strong>Follow us on</strong></p>
                    <div className='text-center'>
                        <a href={LinkedInLink} target="_blank" rel="noopener noreferrer"><img className='social-icon' src={linkedIcon} alt='' height='40'/></a>
                        <a href={TwitterLink} target="_blank" rel="noopener noreferrer"><img className='social-icon' src={xIcon} alt=''  height='40'/></a>
                        <a href={YoutubeLink} target="_blank" rel="noopener noreferrer"><img className='social-icon' src={youtubeIcon} alt=''  height='50' /></a>
                        <a href={DISCORD_LINK} target="_blank" rel="noopener noreferrer"><img className='social-icon' src={discordIcon} alt=''  height='50' /></a>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};
export default SignUpComponent;
