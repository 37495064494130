import React from 'react';

import Dashboard from '../dashboard/dashboard.component';
import Home from '../home/home.component';
import {
  ROUTE_DASHBOARD_PATH,
  ROUTE_HOME_PATH,
} from './constants';

export const routes = [
    { path: ROUTE_DASHBOARD_PATH, Component: <Dashboard /> },
    { path: ROUTE_HOME_PATH, Component: <Home /> },
];


