import { jwtDecode } from 'jwt-decode';
import { toast } from 'react-toastify';

import { ROUTE_LOGIN_PATH } from '../routes/constants';

export const validateTokenAndRedirect = (navigate) => {
    const token = sessionStorage.getItem('accessToken');
    if (!token) {
        //toast.dismiss();
        toast.error('Please login to continue.', { toastId: "token-unavailable" });
        navigate(ROUTE_LOGIN_PATH);
        return false;
    }

    try {
        const decodedToken = jwtDecode(token);
        if (decodedToken.exp * 1000 < Date.now()) {
            //toast.dismiss();
            toast.error('Unauthorized usage. Please login again.', { toastId: "token-expired" });
            navigate(ROUTE_LOGIN_PATH);
            return false;
        }
        return true;
    } catch (error) {
        //toast.dismiss();
        toast.error('Something went wrong. Please login again.', { toastId: "token-decode-error" });
        navigate(ROUTE_LOGIN_PATH);
        return false;
    }
};
