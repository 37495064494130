import React from 'react';

import { Link } from 'react-router-dom';
import {
  Button,
  Col,
  Container,
} from 'reactstrap';

import {
  BACK_TO_HOME_PAGE,
  Error2Head,
  ErrorPageContent,
} from '../constant/index';

const ErrorPage = () => {
  return (
       <div className='page-wrapper compact-wrapper' id='pageWrapper'>
          <div className='error-wrapper'>
            <Container>
              <div className='svg-wrapper error2'>
                {/* <Error2/> */}
              </div>
              <Col md="8" className="offset-md-2">
                  <h3>{Error2Head}</h3>
                  <p className='sub-content'>{ErrorPageContent}</p>
                  <Link to={`${process.env.PUBLIC_URL}/login`}>
                      <Button>{BACK_TO_HOME_PAGE}</Button>
                  </Link>
              </Col>
            </Container>
          </div>
      </div>
  )
}
export default ErrorPage