import React, { useEffect } from 'react';

import {
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  trackError,
  trackPageView,
} from '../../mixpanel/mixpanel';
import { validateTokenAndRedirect } from '../utility/validateLocalStorageToken';

const StripePrice = () => {
    const location = useLocation();
    const { customerEmail } = location.state || {};
    const navigate = useNavigate();

    useEffect(() => {
        trackPageView("Price Table Page");
        if (!validateTokenAndRedirect(navigate)) {
            console.error('Invalid token');
            toast.error('Invalid token');
            trackError('Invalid token');
            return;
        }
    }, [navigate]);

    return (
        <stripe-pricing-table
            pricing-table-id={process.env.REACT_APP_STRIPE_TABLE_ID}
            publishable-key={process.env.REACT_APP_STRIPE_KEY}
            customer-email={customerEmail}
        >
        </stripe-pricing-table>
    );
};

export default StripePrice;
