import React from 'react';

import {
  Route,
  Routes,
} from 'react-router-dom';

import Layout from '../layout/layout.component';
import { routes } from './routes';

const LayoutRoutes = () => {
  return (
      <Routes>
      {routes.map(({ path, Component },i) => (
        <Route element={<Layout />} key={i}>
            <Route path={path} element={Component} />
          </Route>
      ))}
      </Routes>
  );
};

export default LayoutRoutes;