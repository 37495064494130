import mixpanel from 'mixpanel-browser';

import { CONST_USER_ID } from '../components/constant';

const isNotProduction = process.env.REACT_APP_ENV === 'production';
mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, { debug: isNotProduction });

let isMixpanelBlocked = false;

const mixpanelTrack = (eventName, properties = {}, email, name) => {
  try {
    if(isNotProduction){
      return;
    }
    const userId = sessionStorage.getItem(CONST_USER_ID);
    if(userId){
      mixpanel.identify(userId);
    }
    if(email){
      mixpanel.people.set({ email: email});
    }
    if(name){
      mixpanel.people.set({ name: name});
    }
    if(eventName){
    mixpanel.track(eventName, properties);
    }
  } catch (error) {
    isMixpanelBlocked = true;
  }
};

const trackName = (name) => {
  mixpanelTrack(null,null, null,name);
}

const trackEmail = (email) => {
  mixpanelTrack(null,null, email);
}

const trackPageView = (pageName) => {
  mixpanelTrack("Page View", { page: pageName });
}

const trackButtonClick = (eventName) => {
  mixpanelTrack("Button Click", { event: eventName });
}

const trackEvent = (eventName) => {
  mixpanelTrack("Event", { event: eventName });
}

const trackError = (errorMessage) => {
  mixpanelTrack("Error", { message: errorMessage });
}

export {
  isMixpanelBlocked,
  trackButtonClick,
  trackEmail,
  trackError,
  trackEvent,
  trackName,
  trackPageView,
};