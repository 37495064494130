import React from 'react';
import './App.css';
import Routers from './components/routes';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports';
Amplify.configure(awsExports);

function App() {
  return (
    <div>
      <ToastContainer />
      <Routers />
    </div>
  );
}

export default App;
